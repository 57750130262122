import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingMain from "./component/Loader/LoadingMain";
import PrivateRoute from "./PrivateRoute";
import { RedirectPage } from "./component/RedirectPage";
import PaymentFail from "./component/monetization/PaymentFail";
import PaymentSuccess from "./component/monetization/PaymentSuccess";

const Share_Page = lazy(() => import("./component/Sharing/Share_Page"));
const UserDashboard = lazy(() =>
  import("./component/UserDashboard/UserDashboard")
);
const DesignEditor = lazy(() =>
  import("./component/DesignEditor/DesignEditor")
);
const Login = lazy(() => import("./component/Auth/Login"));
const Logout = lazy(() => import("./component/Auth/Logout"));
const PaymentPlan = lazy(() => import("./component/monetization/PaymentPlan"));
const DashboardMemory = lazy(() =>
  import("./component/UserDashboard/DashboardMemory/DashboardMemory")
);
const DashboardTrash = lazy(() =>
  import("./component/UserDashboard/DashboardTrash/DashboardTrash")
);
const DashboardUploads = lazy(() =>
  import("./component/UserDashboard/DashboardUploads/DashboardUploads")
);
const DashboardTemplate = lazy(() =>
  import("./component/UserDashboard/DashboardTemplate/DashboardTemplate")
);
const StudioMain = lazy(() => import("./component/studio/StudioMain"));

const RoutesConfig = ({ isAuthenticated, userId, setIsAuthenticated }) => {
  return (
    <Routes>
      {/* Login Route */}
      <Route
        path="/login"
        element={
          <Suspense fallback={<div></div>}>
            <Login setIsAuthenticated={setIsAuthenticated} />
          </Suspense>
        }
      />
      <Route
        path="/logout"
        element={
          <Suspense fallback={<div></div>}>
            <Logout setIsAuthenticated={setIsAuthenticated} />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense fallback={<div></div>}>
            <Login setIsAuthenticated={setIsAuthenticated} />
          </Suspense>
        }
      />
      {/* Protected Routes */}
      {/* <Route
        path="/"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <div
                  className="align-items-center d-flex justify-content-center  w-100 h2 "
                  style={{ height: "100vh" }}
                >
                  <LoadingMain />
                </div>
              }
            >
              <UserDashboard />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/templates"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/templates"
          element={
            <Suspense fallback={<div></div>}>
              <UserDashboard content={<DashboardTemplate />} />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/cloudStorage"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/cloudStorage"
          element={
            <Suspense fallback={<div></div>}>
              <UserDashboard content={<DashboardMemory />} />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/trash"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/trash"
          element={
            <Suspense fallback={<div></div>}>
              <UserDashboard content={<DashboardTrash />} />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/uploads"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/uploads"
          element={
            <Suspense fallback={<div></div>}>
              <UserDashboard content={<DashboardUploads />} />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/share"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/share"
          element={
            <Suspense fallback={<div></div>}>
              <Share_Page />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/editor/:cat"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/editor/:cat/:subcat"
          element={
            <Suspense fallback={<div></div>}>
              <DesignEditor />
            </Suspense>
          }
        />
        <Route
          path="/editor/:cat"
          element={
            <Suspense fallback={<div></div>}>
              <DesignEditor />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/playground/:modelKey"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/playground/:modelKey"
          element={
            <Suspense fallback={<div></div>}>
              <StudioMain />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/payment-plan"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            propsuserId={userId}
          />
        }
      >
        <Route
          path="/payment-plan"
          element={
            <Suspense fallback={<div></div>}>
              <PaymentPlan />
            </Suspense>
          }
        />
      </Route> */}

      {/* custom unprotected */}

      <Route
        path="/"
        element={
          <Suspense fallback={<div></div>}>
            <UserDashboard />
          </Suspense>
        }
      />
      <Route
        path="/templates"
        element={
          <Suspense fallback={<div></div>}>
            <UserDashboard content={<DashboardTemplate />} />
          </Suspense>
        }
      />
      <Route
        path="/cloudStorage"
        element={
          <Suspense fallback={<div></div>}>
            <UserDashboard content={<DashboardMemory />} />
          </Suspense>
        }
      />
      <Route
        path="/trash"
        element={
          <Suspense fallback={<div></div>}>
            <UserDashboard content={<DashboardTrash />} />
          </Suspense>
        }
      />
      <Route
        path="/uploads"
        element={
          <Suspense fallback={<div></div>}>
            <UserDashboard content={<DashboardUploads />} />
          </Suspense>
        }
      />
      <Route
        path="/share"
        element={
          <Suspense fallback={<div></div>}>
            <Share_Page />
          </Suspense>
        }
      />
      <Route
        path="/editor/:cat/:subcat"
        element={
          <Suspense fallback={<div></div>}>
            <DesignEditor />
          </Suspense>
        }
      />
      <Route
        path="/editor/:cat"
        element={
          <Suspense fallback={<div></div>}>
            <DesignEditor />
          </Suspense>
        }
      />
      <Route
        path="/payment-plan"
        element={
          <Suspense fallback={<div></div>}>
            <PaymentPlan />
          </Suspense>
        }
      />

      {/* <Route path="/nft" element={<PrivateRoute isAuthenticated={isAuthenticated} propsuserId={userId} />}>
        <Route
          path="/nft"
          element={
            <Suspense fallback={<div></div>}>
              <App/>
            </Suspense>
          }
        />
      </Route> */}

      {/* Unprotected routes */}

      <Route
        path="/payment-fail"
        element={
          <Suspense fallback={<div></div>}>
            <PaymentFail />
          </Suspense>
        }
      />
      <Route
        path="/payment-success"
        element={
          <Suspense fallback={<div></div>}>
            <PaymentSuccess />
          </Suspense>
        }
      />
      {/* Wildcard Routes */}
      <Route path="*" element={<RedirectPage redirectPath="dashboard" />} />
      <Route
        path="/editor/:cat/:subcat/*"
        element={<RedirectPage redirectPath="editor" />}
      />
    </Routes>
  );
};

export default RoutesConfig;
